
/**
 * Project overview All the stats for the project
 * and a link to the projects that have status alert or warning
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import { Component, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions, mapMutations } from "vuex";

import { Device } from "@/interface/DeviceInterface";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";
import DashboardSections from "@/model/ModelDashBoardSections";
import ManualDevice from "@/model/ModelDashBoardSections";
import SingleBoardInformationModal from "@/components/project/SingleProject/Schematic/InformationModal/SingleBoardInformationModal.vue";
import DashboardItem from "@/components/shared/DashboardItem.vue";
import AlarmTableOverview from "@/components/dashboard/AlarmTableOverview.vue";
import ManualDeviceTable from "@/components/dashboard/ManualDeviceTable.vue";
import { CongestionProject, DeviceAdditionalStatusDashboard, DeviceStatusDashboard } from "@/interface/DashboardDataInterface";
import VueI18n from "vue-i18n";

interface Pagination {
  currentPage: number;
  totalPages: number;
  nextPage: number;
}

interface ActionStatus {
  class: string;
  msg: string;
}

@Component({
  name: "ProjectOverview",
  components: {
    SingleBoardInformationModal,
    DashboardItem,
    ManualDeviceTable,
    AlarmTableOverview
  },
  computed: {
    ...mapGetters("Dashboard", {
      PROJECTS_SECTIONS_HEALTH: "GetAllProjectSectionsHealth",
      PROJECTS_STATUS_METRICS: "GetAllProjectStatusMetrics",
      BOARDS_STATUS_METRICS: "GetAllProjectBoardStatusMetrics",
      DEVICE_STATUS_METRICS: "GetAllProjectDeviceStatusMetrics",
      DEVICE_ADDITIONAL_STATUS_METRICS: "GetAllProjectDeviceAdditionalStatusMetrics",
      PROJECT_IN_CONGESTION: "GetProjectsInCongestionMode",
      MANUAL_DEVICE_LIST: "GetAllManualDevices",
      PROJECTS_HEALTH_PAGINATION: "GetPaginationOfAllDevices"
    })
  },
  methods: {
    ...mapActions("Dashboard", {
      GET_ALL_SECTIONS_STATUS: "GET_ALL_PROJECTS_SECTIONS_DASHBOARD"
    }),
    ...mapActions("projects", {
      GetProjectDetailsFromBackEnd: "DetailsOfProject"
    }),
    ...mapMutations("projects", {
      ResetProject: "ResetProject"
    })
  }
})
export default class ProjectService extends Mixins(KmFormatted) {
  $t!: VueI18n["t"];
  // Vuex
  readonly PROJECTS_SECTIONS_HEALTH!: Array<DashboardSections>;
  readonly PROJECTS_STATUS_METRICS!: Array<number>;
  readonly BOARDS_STATUS_METRICS!: Array<number>;
  readonly PROJECTS_HEALTH_PAGINATION!: Pagination;
  readonly MANUAL_DEVICE_LIST!: Array<ManualDevice>;
  readonly PROJECT_IN_CONGESTION!: Array<CongestionProject>;
  readonly DEVICE_STATUS_METRICS!: DeviceStatusDashboard;
  readonly DEVICE_ADDITIONAL_STATUS_METRICS!: DeviceAdditionalStatusDashboard;

  private GET_ALL_SECTIONS_STATUS!: Function;
  private GetProjectDetailsFromBackEnd!: Function;
  private ResetProject!: Function;

  projectStatus: Array<Device> = [];

  updateProjectsHealthInterval: Array<any> = [];
  healthGetStatus: null | ActionStatus = null;
  projectInCongestionHeaders = [
    {
      key: "name",
      label: this.translateTableHeaderLabel("projectName"),
      sortable: true
    },
    {
      key: "direction",
      label: this.translateTableHeaderLabel("direction"),
      sortable: true
    },
    {
      key: "status",
      label: this.translateTableHeaderLabel("situation"),
      sortable: true
    }
  ];
  webUrl = process.env.VUE_APP_WEB_URL;

  // Open Info Modal Variables
  alertDetails = {
    projectId: 0,
    sectionId: 0,
    devicePosition: ""
  };

  async created() {
    let firstGet = await this.GET_ALL_SECTIONS_STATUS(0);

    if (firstGet.status === 200) {
      await this.createIntervalGetProjectHealth();

    } else if (firstGet.status !== 200) {
      this.healthGetStatus = {
        class: "danger",
        msg: this.$t("dashboard.metricsProjects.projectHealthRequestError").toString()
      };
    }
  }

  beforeDestroy() {
    this.clearAllIntervalsBeforeLeave();
  }

  private get rows() {
    return this.PROJECTS_SECTIONS_HEALTH.length;
  }

  getTotal(item: any): number {
    let total = 0;
    for (const key in item) {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        total = total + item[key];
      }
    }
    return total;
  }

  private async clearAllIntervalsBeforeLeave() {
    let promises = this.updateProjectsHealthInterval.map(async (intervalId: number) => {
      await clearInterval(intervalId);
    });
    await Promise.all(promises);
  }

  private async createIntervalGetProjectHealth() {
    let timeToGetProjectsHealth = 1000 * 30;
    const newHealthInterval = setInterval(async () => {
      console.log("### INTERVAL IS RUNNING #####");
      this.healthGetStatus = null;
      let res = await this.GET_ALL_SECTIONS_STATUS(this.PROJECTS_HEALTH_PAGINATION.currentPage);
      if (res.status !== 200) {
        this.healthGetStatus = {
          class: "danger",
          msg: this.$t("dashboard.metricsProjects.projectHealthRequestError").toString()
        };
      }
    }, timeToGetProjectsHealth);

    this.updateProjectsHealthInterval.push(newHealthInterval);
  }

  resetProject() {
    this.ResetProject();
    this.alertDetails = {
      projectId: 0,
      sectionId: 0,
      devicePosition: ""
    };
  }

  async handleOpenInfoModal(item: { projectId: number; sectionId: number; devicePosition: string }) {
    const { projectId, sectionId, devicePosition } = item;
    await this.GetProjectDetailsFromBackEnd(projectId);
    this.alertDetails = {
      projectId,
      sectionId,
      devicePosition
    };

    this.$bvModal.show("openDisplayModalDetails");
  }

  translateTableHeaderLabel(key: string) {
    return this.$t(`dashboard.metricsProjects.tableLabels.${key}`);
  }

  getImageSource(trafficJam: boolean): string {
    const url = `${this.webUrl}/img/48x48/`;
    const isTrafficJam = trafficJam ? "Stau" : "Stgefahr";
    return url + isTrafficJam + ".bmp";
  }
}
